import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import { getDiscordAvatar, getMinecraftDetails, getMinecraftPlayerName, getUserDetails, getApiUrl, getMinecraftHead } from '../util/utils';
import React, { Component } from 'react';
import { Button, Row, Col, Form, Image } from 'react-bootstrap';
import { BarLoader } from 'react-spinners'

export default class Dashboard extends Component {


    constructor(props) {
        super(props);
        this.state = { user: null, isLoaded: false, donator_data: null, showToast: false, copiedText: "" };
        this.handleCopy = this.handleCopy.bind(this);
    }

    handleCopy(text) {
        navigator.clipboard.writeText(text);
        this.setState({ copiedText: text, showToast: true });
        setTimeout(() => this.setState({showToast: false}), 2000);
    }

    componentDidMount() {
        getUserDetails().then(data => {
            this.setState({ user: data.data, isLoaded: true })
        }).catch((err) => {
            console.log(err)
        })

        fetch('https://mc-api.craig.software/vips')
            .then((response) => response.json())
            .then((res) => {
                this.setState({ donator_data: res.data, isLoaded: true, });
            })

        document.title = "Dashboard - User";
    }




    render() {
        return (
            <div>
                {!this.state.isLoaded && <BarLoader
                    color="white"
                    height={8}
                    loading
                    speedMultiplier={1}
                    width={600}
                />}
                
                <div class="ui container inverted">
                    <div class="ui stackable grid">
                        <div class="two wide column">
                                {this.state.isLoaded && this.state.user !== null && (
                                    <div class="ui card inverted">
                                        <div class="image">
                                            <img src={getMinecraftHead(this.state.user.mc_uuid)} />
                                        </div>
                                        <div class="content">
                                            <a class="header">{this.state.user.username}</a>
                                        </div>
                                    </div>
                                )}
                        </div>


                        <div class="ten wide column inverted">

                            <div class="ui segment inverted">
                                {this.state.isLoaded && this.state.user !== null && (
                                    <div class="ui list inverted">
                                        <div class="item">
                                            <div class="content">
                                                <div class="header">Minecraft UUID:</div>
                                                {this.state.user.mc_uuid}
                                                <i
                                                    class="copy icon"
                                                    onClick={() => this.handleCopy(this.state.user.mc_uuid)}
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="content">
                                                <div class="header">Minecraft username:</div>
                                                {this.state.user.username} <i
                                                    class="copy icon"
                                                    onClick={() => this.handleCopy(this.state.user.username)}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div class="ui segment inverted">
                                {this.state.isLoaded && this.state.user !== null && (
                                    <div class="ui list inverted">
                                        <div class="item">
                                            <div class="content">
                                                <div class="header">Minecraft UUID:</div>
                                                {this.state.user.mc_uuid}
                                                <i
                                                    class="copy icon"
                                                    onClick={() => this.handleCopy(this.state.user.mc_uuid)}
                                                ></i>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="content">
                                                <div class="header">Minecraft username:</div>
                                                {this.state.user.username} <i
                                                    class="copy icon"
                                                    onClick={() => this.handleCopy(this.state.user.username)}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    {this.state.showToast && (
                        <div class="ui success message">
                            <i class="check icon"></i>
                            {this.state.copiedText} has been copied to your clipboard.
                        </div>
                    )}
                </div> </div>
        );
}};
