import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Container, Nav, Button, NavDropdown, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { React, Component } from 'react';
import { getDiscordAvatar, getMinecraftDetails, getUserDetails, getApiUrl, isUserAdmin, getMinecraftHead } from '../../util/utils';
import { Discord } from 'react-bootstrap-icons';

export default class TopNavigation extends Component {

    constructor(props) {
        super(props);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.state = { user: null };
    }

    handleLoginClick() {
        window.location.href = getApiUrl() + '/auth/minecraft'
    }

    handleLogoutClick() {
        window.location.href = getApiUrl() + '/auth/logout'
    }

    componentDidMount() {
        getUserDetails().then(data => {
            this.setState({ user: data })
        }).catch((err) => {
            console.log(err)
        })

    }


    render() {
        const userData = this.state.user;
        let button = userData === null ? <Button onClick={this.handleLoginClick}> <h4><Discord/></h4> Login</Button> : <Button onClick={this.handleLogoutClick}>Logout</Button>;


        return (

            <> <div style={{ background: 'red', color: 'white', padding: '10px', textAlign: 'center' }}>
            This site is deprecated. It's information may vary and pages may break. Please go to <a href='https://craig.software'>https://craig.software</a>
        </div><Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">



                <Container>
                    <Navbar.Brand href="#/">Craig</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#/">Home</Nav.Link>
                            <Nav.Link href="#/projects">Projects</Nav.Link>
                            <Nav.Link href="#/hall-of-fame">Hall of Fame</Nav.Link>

                            <NavDropdown title="Regeneration" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#/skins">Custom Skins</NavDropdown.Item>
                            <NavDropdown.Item href="#/api-skins">Trending Skins</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="https://www.bisecthosting.com/clients/aff.php?aff=3107">Need a Server?</Nav.Link>

                        </Nav>
                        <Nav>
                            {userData !== null && <Image src={getMinecraftHead(this.state.user.data.mc_uuid)} roundedCircle width={35} />}

                            {userData == null && button}

                            {userData !== null &&

                                <NavDropdown title={userData.data.username} id="collasible-nav-dropdown">

                                    <NavDropdown.Item><Link to="u/dashboard">Dashboard</Link></NavDropdown.Item>

                                    {isUserAdmin(this.state.user) && <NavDropdown.Item><Link to="a/add-vip">Add Donator</Link></NavDropdown.Item>}

                                    {isUserAdmin(this.state.user) && <NavDropdown.Item><Link to="a/donators">Edit Donators</Link></NavDropdown.Item>}
                                    <NavDropdown.Item onClick={this.handleLogoutClick}>Logout</NavDropdown.Item>

                                </NavDropdown>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar></>
        )
    };
}

//  {this.state.user !== null && this.state.user.data.role === "admin" && <Button onClick={this.handleLogoutClick}>Admin</Button>}
//{this.state.user !== null && <Image src={getDiscordAvatar(this.state.user.data.discordId, this.state.user.data.avatar)} roundedCircle width={80} />}
