
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import SkinComponent from '../components/SkinComponent';
import { BarLoader } from 'react-spinners'
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { getApiUrl, getUserDetails, isUserAdmin } from '../util/utils';
import axios from "axios";

export default class Skinpack extends React.Component {


    constructor() {
        super()
        this.state = {
            skins: [],
            skinData: [],
            isLoaded: false,
            searchQuery: '',
            user: null,
            currentPage: 1,
            itemsPerPage: 20
                }
    }

    handlePageChange = (newPage) => {
        this.setState({ currentPage: newPage });
    }

    getPaginationButtons() {
        const { skinData, currentPage, itemsPerPage } = this.state;
        const totalPages = Math.ceil(skinData.length / itemsPerPage);
        let start, end;
        if (currentPage <= (itemsPerPage / 2)) {
            start = 1;
            end = itemsPerPage;
        } else if (currentPage > (totalPages - (itemsPerPage / 2))) {
            start = totalPages - (itemsPerPage - 1);
            end = totalPages;
        } else {
            start = currentPage - (itemsPerPage / 2);
            end = currentPage + (itemsPerPage / 2);
        }
        return Array.from({ length: (end - start) + 1 }, (_, i) => start + i);
    }


    componentDidMount() {

        getUserDetails().then(data => {
            this.setState({ user: data });
        }).catch((err) => {
            this.context.router.history.push("/");
        })


        fetch('https://mc.craig.software/api/skin/skins')
            .then((response) => response.json())
            .then((res) => {
                this.setState({ skinData: res, isLoaded: true })
            })
    }


    render() {
        const { skinData, currentPage, itemsPerPage } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = skinData.slice(indexOfFirstItem, indexOfLastItem);

        function deleteSkin(e) {
            console.log(e.target.id)
            const data = {
                data: e.target.id
            }
            axios.post(getApiUrl() + '/delete-skin', data);
            document.getElementById(e.target.id).remove();
        }

        return (
            <div>
                <div className='justify-content-left'>
                    {!this.state.isLoaded && <BarLoader color="white" height={8} loading speedMultiplier={1} width={window.innerWidth} />}
                    <h1>Skins</h1>
                    <h1>{this.state.skinData.length}</h1>

                    <Form>
                        <Form.Group className="mb-3" controlId="minecraftUser">
                            <Form.Control onChange={e => this.setState({ searchQuery: e.target.value })} type="text" placeholder="Search Skins..." style={{ width: 220 }} />
                        </Form.Group>
                    </Form>
                </div>

                <div className="container-lg">
                    <div className="row text-center justify-content-center align-items-center mx-0 px-0">
                        {currentItems
                            .filter(skin => skin.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
                            .map((object) => (
                                <Col>
                                    <div id={object.key}>
                                        <Card bg="dark" style={{ width: '11.5rem' }}>
                                            <Card.Header as="h6" className='developer'>{object.name}</Card.Header>
                                            <Card.Img variant="top" draggable={false} src={"https://api.sprax2013.de/mc/skin/x-url/body/3d?url=" + object.link} id={object._id.timestamp} />
                                            <Card.Body>
                                                <p> <a target="_blank" rel="noreferrer noopener" href={object.link} download={object.key + ".png"}>
                                                    <Button variant="secondary"><i className="fa fa-download"></i> Download</Button>
                                                </a> </p>
                                                {this.state.user !== null && isUserAdmin(this.state.user) && <p><Button variant="warning" id={object.key} onClick={deleteSkin}><i className="fa fa-trash"></i> Delete</Button></p>}
                                            </Card.Body>
                                        </Card>
                                        <br />
                                    </div >
                                </Col>
                            ))}
                    </div>
                </div>

                <br />
                <div className="text-center">
                    <Button variant="secondary" disabled={currentPage === 1} onClick={() => this.handlePageChange(currentPage - 1)}>
                        Previous
                    </Button>

                    {this.getPaginationButtons().map(page => (
                        <Button variant="secondary" disabled={page === currentPage} onClick={() => this.handlePageChange(page)}>
                            {page}
                        </Button>
                    ))}

                    <Button variant="secondary" disabled={currentPage === Math.ceil(skinData.length / this.state.itemsPerPage)} onClick={() => this.handlePageChange(currentPage + 1)}>
                        Next
                    </Button>
                </div>
                <br /><br /><br /><br />
            </div>
        );
    }
}
