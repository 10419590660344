import React, { Component } from 'react';
import { Col, Accordion, Carousel, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { Github, Download, CalendarFill } from 'react-bootstrap-icons';
import $ from 'jquery';


export default class Mod extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false
        }
    }

    async componentDidMount() {
        this.setState({ isLoaded: true })
        $('.ui.accordion').accordion();
    }

    render() {
        return (
            
                <div className="ui items inverted">
                    <div className="item">

                        <a className="image">
                            <img draggable="false" src={this.props.mod.logoUrl} />
                        </a>

                        <div className="content">
                            <a href={this.props.mod.website} className="header">{this.props.mod.title}</a>


                            <p style={{ color: 'white' }}>
                                {this.props.mod.description}
                            </p>

                            <h4 class="ui horizontal divider header inverted">Authors</h4>
                            <div style={{ display: 'flex', gap: 20 }}>
                                {this.props.mod.authors.length > 0 && <p style={{ color: 'white' }}>{this.props.mod.authors.map((author) =>
                                    <a class="ui image blue label">
                                        <img src={author.avatarUrl} />
                                        {author.name}
                                    </a>
                                )}
                                </p>}
                            </div>


                            <p style={{ color: 'white' }}><CalendarFill /> Released {moment(this.props.mod.dateCreated).startOf('seconds').fromNow()} - ({moment(this.props.mod.dateCreated).format('MMMM Do YYYY')})</p>
                            <p style={{ color: 'white' }}><CalendarFill /> Updated {moment(this.props.mod.dateReleased).startOf('seconds').fromNow()} - ({moment(this.props.mod.dateReleased).format('MMMM Do YYYY')})</p>

                        

                            <table class="ui very basic collapsing celled table inverted">
                                <tbody>

                                    {this.props.mod.curseForgeDownloads > 0 &&
                                        <td>
                                            <p class="ui">
                                                <img style={{ width: "20px" }} src="https://i.imgur.com/KWxxSly.png" class="ui mini rounded image" />
                                            </p></td>
                                    }

                                    {this.props.mod.curseForgeDownloads > 0 &&
                                        <td>
                                            <p style={{ color: 'white' }}>{this.props.mod.curseForgeDownloads}</p>
                                        </td>

                                    }

                                    {this.props.mod.modrinthDownloads > 0 &&
                                        <td>
                                            <p class="ui">
                                                <img style={{ width: "20px" }} src="https://docs.modrinth.com/img/logo.svg" class="ui mini rounded image" />
                                            </p></td>
                                    }

                                    {this.props.mod.modrinthDownloads > 0 &&
                                        <td>
                                            {this.props.mod.modrinthDownloads}
                                        </td>
                                    }


                                    <td>
                                        <p class="ui">
                                            <p style={{ color: 'white' }}>Total: {this.props.mod.totalDownloads}</p>
                                        </p>
                                    </td>

                                </tbody>
                            </table>

                            { <Accordion>


                                {Object.keys(this.props.mod.screenshots).length != 0 && <Accordion.Item eventKey="3">

                                    <Accordion.Header className='mc-font'>Screenshots</Accordion.Header>
                                    <Accordion.Body>

                                        <Carousel>
                                            {Object.keys(this.props.mod.screenshots).map((screenshotKey, index) => {
                                                const screenshot = this.props.mod.screenshots[screenshotKey];
                                                return (
                                                    <Carousel.Item>
                                                        <img draggable="false"
                                                            className="d-block w-100"
                                                            src={screenshot.url}
                                                            alt={screenshot.title}
                                                        />
                                                        <Carousel.Caption>
                                                            <div className="ui inverted message">
                                                                <div className="mc-font">
                                                                    {screenshot.title}
                                                                </div>
                                                                <p>{screenshot.description}</p>
                                                            </div>
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                );
                                            })}
                                        </Carousel>
                                    </Accordion.Body>
                                </Accordion.Item>}
                            </Accordion> }
                        </div>
                    </div>

                </div>
           
        )
    }
}
