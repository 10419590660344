
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { BarLoader } from 'react-spinners'
import { Form } from 'react-bootstrap';

export default class Wings extends React.Component {

    constructor() {
        super()
        this.state = {
            skins: [
                'Normal', 'a_dizzle', 'Basalt', 'idkwatthesewingsareclled', 'idkwatthesewingsareclled_cracked', 'Mercy', 'Mossy', 'Quartz'
            ]
        }
    }


    componentDidMount() {

    }



    render() {
        document.title = "Wings";

        return (

            <div>
                <div className="container-lg">
                    <div className="row justify-content-center">

                        {this.state.skins.map((object) =>

                            <a className="orange card ui large image">
                                <img draggable="false" src={ 'https://mc.craig.software/skins/wings/' + object + '.png' }  />
                            </a>

                        )}
                    </div>
                </div><br /><br /><br /><br />
            </div>
        );
    }
}
