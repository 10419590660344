import '../../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import axios from 'axios';
import { Form, Table } from 'react-bootstrap';
import PlayerFrame from '../../components/PlayerFrameComponent';
import { getUserDetails, isUserAdmin } from '../../util/utils';
export default class AdminDonators extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            vips: [],
            searchQuery: '',
            user: ''
        }
    }

    componentDidMount() {

        getUserDetails().then(data => {

            this.setState({ user: data });
            if (!isUserAdmin(data)) {
                this.context.router.history.push("/");
            }
        }).catch((err) => {
            this.context.router.history.push("/");
        }
        )


        fetch('https://mc-api.craig.software/vips')
            .then((response) => response.json())
            .then((res) => this.setState({ vips: res.data }))

        document.title = "Dashboard - Admin";

    }

    render() {
        function deleteUser(e) {
            const data = {
                uuid: e.target.id
            }
            axios.post('https://mc-api.craig.software/post/delete_vip', data);
        }

        return (
            <div>
                <div className="container">
                    <Form>
                        <Form.Group className="mb-3" controlId="minecraftUser">
                            <Form.Label>Minecraft Username</Form.Label>
                            <Form.Control
                                onChange={(e) => this.setState({ searchQuery: e.target.value })}
                                type="username"
                                placeholder="Enter minecraft username"
                            />
                        </Form.Group>
                    </Form>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Minecraft Username</th>
                                <th>VIP Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.vips
                                .filter((player) =>
                                    player.mc_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                )
                                .map((object, index) => (
                                    <tr key={object.uuid}>
                                        <td><img
                                            className="ui avatar image"
                                            src={`https://mc-heads.net/head/${object.uuid}/128.png`}
                                        /></td>
                                        <td>{object.mc_name}</td>
                                        <td><p>{object.vip_type}</p></td>
                                        <td>
                                            <div
                                                className="ui button"
                                                id={object.uuid}
                                                onClick={deleteUser}
                                            >
                                                Delete
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        );


    }
}
