import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
let audio = new Audio('/sounds/motivation.mp3')

const linkStyle = {
    textDecoration: "none",
    color: 'white'
};

export default class PlayerCredit extends Component {

    constructor(props) {
        super(props);
        this.state = { data: this.props.data, playerName: 'Unknown' };
    }

    componentDidMount() {
        fetch('https://playerdb.co/api/player/minecraft/' + this.props.data.author.uuid)
            .then((response) => response.json())
            .then((res) => {
                this.setState({ playerName: res.data.player.username })
            })
    }


    render() {

        function handleClick(e) {
            if (e.target.id === "Graham") {
                audio.play();
            }
        }

        return (
            <Col>
                <div id={this.props.data.name}>
                    <Card bg="dark" style={{ width: '13rem' }}>
                        <Card.Header as="h6" className='developer'>{this.props.data.name}</Card.Header>
                        <Card.Img variant="top" src={"https://api.sprax2013.de/mc/skin/x-url/body/3d?url=" + this.props.data.url} id={this.props.data.name} />
                        <Card.Body>
                            <Card.Text className='developer'>
                                <a href={this.props.data.author.link} class="ui image label">
                                    <img src={'https://api.sprax2013.de/mc/skin/' + this.props.data.author.uuid + '/head?size=64'} />  {this.state.playerName}
                                </a>
                            </Card.Text>
                            <a target="_blank" rel="noreferrer noopener" href={this.props.data.url} download={this.props.data.name + ".png"}>
                            <Button variant="secondary"><i class="fa fa-download"></i> Download</Button></a>
                        </Card.Body>
                    </Card>
                    <br/>
                </div >
            </Col>
        )
    }

}
