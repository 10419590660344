
import '../index.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import SkinComponent from '../components/SkinComponent';
import { BarLoader } from 'react-spinners'
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

export default class Skinpack extends React.Component {


    constructor() {
        super()
        this.state = {
            skins: [],
            skinData: [],
            isLoaded: false,
            searchQuery: '',
            currentPage: 1,
            itemsPerPage: 100
        }
    }

    handlePageChange = (newPage) => {
        this.setState({ currentPage: newPage });
    }

    componentDidMount() {
        fetch('https://mc-api.craig.software/skins')
            .then((response) => response.json())
            .then((res) => {
                this.setState({ skins: res.data, isLoaded: true })
            })
    }



    render() {

        const { skins, currentPage, itemsPerPage } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = skins.slice(indexOfFirstItem, indexOfLastItem);

        document.title = "Skins";

        return (

            <div>
                <div className='justify-content-left'>
                    {!this.state.isLoaded && <BarLoader color="white" height={8} loading speedMultiplier={1} width={window.innerWidth} />}
                    <h1>Skins</h1>
                </div>
                <div className="container-lg">
                    <div className="row text-center justify-content-center align-items-center mx-0 px-0">
                        <Form>
                            <Form.Group className="mb-3" controlId="minecraftUser">
                                <Form.Control onChange={e => this.setState({ searchQuery: e.target.value })} type="text" placeholder="Search Skins..." style={{ width: 400 }} />
                            </Form.Group>
                        </Form>
                        {currentItems
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .sort((b, a) => a.author.link.localeCompare(b.author.link))
                            .filter(skin => skin.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
                            .map((object) => <SkinComponent data={object} key={object.name} />)}
                    </div>
                </div>
                <br />
                <div className="text-center">
                    <Button variant="secondary" disabled={currentPage === 1} onClick={() => this.handlePageChange(currentPage - 1)}>
                        Previous
                    </Button>
                    <Button variant="secondary" disabled={currentPage === Math.ceil(skins.length / itemsPerPage)} onClick={() => this.handlePageChange(currentPage + 1)}>
                        Next
                    </Button>
                </div>
                <br /><br />   <br /><br />
            </div>
        );
    }
}
