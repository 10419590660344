import React from 'react';
import { BarLoader } from 'react-spinners'
import { getDiscordAvatar, getApiUrl } from '../util/utils';

export default class Projects extends React.Component {
    state = { activeIndex: 0 }

    constructor() {
        super()
        this.state = {
            projects: [],
            downloadCount: 0,
            isLoading: false,
            users: []
        }
    }

    async componentDidMount() {
        document.title = "Home";
        await fetch('https://mc.craig.software/api/skin/mod_info').then((response) => response.json()).then(res => {
            this.setState({ projects: res });

            let downloads = 0;

            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                downloads += element.downloads;
            }
            this.setState({ downloadCount: downloads })
        })

        this.setState({ isLoaded: true })
    }

    render() {

        return (
            <div>
                {!this.state.isLoaded && <BarLoader color="white" height={8} loading speedMultiplier={1} width={window.innerWidth} />}

                <h1>Home</h1>
                <div className="row text-center justify-content-center align-items-center mx-0 px-0">

                <div className="ui ten column grid">
                        {this.state.projects.sort((b, a) => a.downloads.total - b.downloads.total).map((project) =>
                            <div className="column" key={project.information.title}>
                                <div className="ui fluid card inverted">
                                    <div className="image">
                                    <img draggable={false} src={project.images.logo} alt={project.information.title} />
                                    </div>
                                    <div className="content">
                                        <a className="header">{project.information.title}</a>
                                    </div>
                                </div>
                            </div>

                        )}
                </div>
                </div>
                <br /><br /><br />
            </div>
        );
    }
}



