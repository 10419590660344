import Mod from '../components/ModComponent';
import React from 'react';
import { BarLoader } from 'react-spinners'
import { Github, Download, Search } from 'react-bootstrap-icons';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { Card, Elevation, Icon, Intent, Spinner } from "@blueprintjs/core";
import Button from 'react-bootstrap/Button';
import { FaClock, FaDownload, FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa';
import { abbreviateNumber } from "js-abbreviation-number";

export default class Projects extends React.Component {
    state = { activeIndex: 0 }

    constructor() {
        super()
        this.state = {
            projects: [],
            downloadCount: 0,
            isLoading: false,
            sortBy: "most_downloaded"
        }
    }

    async componentDidMount() {
        document.title = "Projects";
        await fetch('https://mc.craig.software/api/skin/mod_info').then((response) => response.json()).then(res => {
            this.setState({ projects: res });
            
            let downloads = 0;

            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                downloads += element.totalDownloads;
            }
            this.setState({ downloadCount: downloads })

        })
        this.setState({ isLoaded: true })
    }

    render() {

        const { projects, isLoaded, downloadCount } = this.state;

        // Define sorting functions
        const sortByNewest = (a, b) => moment(b.dateCreated) - moment(a.dateCreated);
        const sortByOldest = (a, b) => moment(a.dateCreated) - moment(b.dateCreated);
        const sortByLastUpdated = (a, b) => moment(b.dateReleased) - moment(a.dateReleased);
        const sortByMostDownloaded = (a, b) => b.totalDownloads - a.totalDownloads;
        const sortByLeastDownloaded = (a, b) => a.totalDownloads - b.totalDownloads;

        // Determine which sorting function to use based on user selection
        let sortedProjects;
        switch (this.state.sortBy) {
            case 'newest':
                sortedProjects = projects.sort(sortByNewest);
                break;
            case 'oldest':
                sortedProjects = projects.sort(sortByOldest);
                break;
            case 'most_downloaded':
                sortedProjects = projects.sort(sortByMostDownloaded);
                break;
            case 'least_downloaded':
                sortedProjects = projects.sort(sortByLeastDownloaded);
                break;
            case 'last_updated':
                sortedProjects = projects.sort(sortByLastUpdated);
                break;
            default:
                sortedProjects = projects;
                break;
        }

        return (

            <div className="ui inverted"><br />
                <div class="ui bottom attached inverted">

                    <div>
                        {!this.state.isLoaded && <BarLoader color="white" height={8} loading speedMultiplier={1} width={window.innerWidth} />}
                        {!this.state.isLoaded && <p style={{ color: 'white' }}>Loading Projects...</p>}

                        <div className="container-lg justify-content-center align-items-center mc-font">
                            <h4 class="ui horizontal divider header"></h4>

                            <h1>Overall Downloads: {this.state.downloadCount}</h1>
                            <div className="ui buttons">
                                <Button variant="secondary" onClick={() => this.setState({ sortBy: 'newest' })}>
                                    <FaClock /> Newest
                                </Button>
                                <Button variant="secondary" onClick={() => this.setState({ sortBy: 'oldest' })}>
                                    <FaClock /> Oldest
                                </Button>
                                <Button variant="secondary" onClick={() => this.setState({ sortBy: 'last_updated' })}>
                                    <FaClock /> Last Updated
                                </Button>
                                <Button variant="secondary" onClick={() => this.setState({ sortBy: 'most_downloaded' })}>
                                    <FaDownload /> Most Downloaded
                                </Button>
                                <Button variant="secondary" onClick={() => this.setState({ sortBy: 'least_downloaded' })}>
                                    <FaDownload /> Least Downloaded
                                </Button>
                            </div>

                            <h1 class="ui horizontal divider header inverted">===== Minecraft Mods =====</h1>

                            <div class="ui divided items inverted">
                                {sortedProjects.map((project) =>


                                    <div class="item">

                                        <div class="image">
                                            <img src={project.images.logo} />
                                        </div>
                                        <div class="content">
                                            <h5 className='developer'>{project.information.title}</h5>
                                            <div class="meta">
                                                <span class="cinema">{project.information.summary}</span>
                                            </div>
                                            <div class="description">
                                                <p style={{ color: 'white' }}> Released {moment(project.dates.created).startOf('seconds').fromNow()} - ({moment(project.dates.created).format('MMMM Do YYYY')})</p>
                                                <p style={{ color: 'white' }}> Updated {moment(project.dates.updated).startOf('seconds').fromNow()} - ({moment(project.dates.updated).format('MMMM Do YYYY')})</p>

                                                <div class="ui mini statistics inverted">

                                                    {project.downloads.curseforge > 0 && <div class="ui tiny statistic">
                                                        <div class="value">
                                                        {abbreviateNumber(project.downloads.curseforge, 2)}
                                                        </div>
                                                        <div class="label">
                                                            CurseForge
                                                        </div>
                                                    </div>}

                                                    {project.downloads.modrinth > 0 && <div class="ui mini statistic">
                                                        <div class="value">
                                                            {abbreviateNumber(project.downloads.modrinth, 2)}
                                                        </div>
                                                        <div class="label">
                                                            ModRinth
                                                        </div>
                                                    </div>}

                                                    {project.downloads.total > 0 && <div class="ui mini statistic">
                                                        <div class="ui mini statistic">
                                                            <div class="value">
                                                            {abbreviateNumber(project.downloads.total, 2)}
                                                            </div>
                                                            <div class="label">
                                                                Total
                                                            </div>
                                                        </div></div>
                                                    }

                                                </div>
                                            </div>
                                            <div class="extra">
                                                <div class="ui right floated primary danger">
                                                    <div class="ui buttons">
                                                        {project.downloads.curseforge > 0 && <a href={"https://beta.curseforge.com/minecraft/mc-mods/" + project.curse.slug}><button class="ui orange button compact mini">CurseForge<i class="right chevron icon"></i></button></a>}
                                                        {project.downloads.modrinth > 0 && <a href={"https://modrinth.com/mod/" + project.modrinth.slug} ><button class="ui green button compact mini">ModRinth<i class="right chevron icon"></i></button></a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div></div>
                </div>

                <br /><br /><br />
            </div>
        );
    }
}



